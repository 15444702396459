.modal {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  align-items: center;
  display: none;
}

.modal.is_active {
  display: flex;
}

.modal_bg {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9998;
}

.modal_wrap {
  text-align: right;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  z-index: 10000;
  position: relative;
}

.modal_inner {
  background-color: #fff;
  text-align: left;
  padding: 30px 30px 40px;
  font-size: 16px;
}

.modal_close {
  color: #fff;
  font-size: 32px;
  position: absolute;
  top: -30px;
  right: 18px;
}
