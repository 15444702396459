/* eslint-disable*/
/* **css**  */
/* @import '~rsuite/styles/index.less';
/* Style customization. */
/* @import 'custom-theme.less'; */

.rs-picker-toggle-value {
  color: black !important;
}

/* カレンダーのプロパティ */
.rs-picker-menu {
  top: 0px !important;
  left: 0px !important;
  box-shadow: none !important;
  position: absolute;
}

.rs-picker-toolbar-ranges {
  width: 255px !important;
}

.rs-picker-toolbar {
  padding: 12px 0 !important;
}

.rs-picker-toggle-caret {
  font-size: 26px;
  right: 5px;
}

.rs-picker-toggle {
  padding-right: 40px;
}

.rs-picker-toggle-textbox {
  padding-right: 20px;
}

/* 選択するボタン */
.rs-btn-primary {
  background-color: #718cc6;
  font-size: 0.75rem;
  padding: 5px 15px;
  display: none;
}

/* 閉じるボタン */
.rs-btn-close .rs-icon {
  display: none;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background-color: #718cc6;
}

.rs-btn-link {
  color: #718cc6;
}

/* text editor*/
.rs-picker-default .rs-picker-toggle.rs-btn {
  padding-top: 7px;
  padding-bottom: 7px;
  width: 100%;
  /* 選択窓の幅 */
}

/* picked date align */
.rs-picker-default .rs-picker-toggle {
  position: relative;
  z-index: 5;
  /* padding-right: 10px; */
  display: inline-block;
  outline: none;
  cursor: pointer;
  color: red;
  font-size: 11px;

  /* color: #575757; */
  border: 1px solid #e5e5ea;
  padding-left: 10px;
}

/* ok button style */
.rs-picker-toolbar-right-btn-ok {
  text-align: center;
  cursor: pointer;
  outline: 0;
  border: none;
  padding: 8px 12px;
  font-size: 14px;
  border-radius: 30px;
  color: #fff;
  background-color: #fcb542;

  width: 100px;
}

.rs-picker-toolbar-right-btn-cancel {
  text-align: center;
  cursor: pointer;
  outline: 0;
  border: none;
  padding: 8px 24px;
  font-size: 14px;
  border-radius: 30px;
  color: #fff;
  background-color: #2c333a;

  width: 100px;
}

/* range style  */
.rs-picker-menu .rs-calendar .rs-calendar-table-cell-content {
  padding-left: 0;
  padding-right: 0;
  border-radius: 21px;
  display: inline-block;
}

.rs-picker-menu {
  overflow: visible;
}

.rs-picker-toolbar-ranges {
  border-radius: 5px;
}

.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active {
  border: none;
  box-shadow: none;
}
